import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import styles from './App.module.scss';
import { ReactComponent as IconSkillApps } from './assets/images/skills/apps.svg';
import { ReactComponent as IconSkillDevelopment } from './assets/images/skills/development.svg';
import { ReactComponent as IconSkillPrototype } from './assets/images/skills/prototype.svg';
import { ReactComponent as LogoCompact } from './assets/logos/compact.svg';
import { ReactComponent as LogoHinged } from './assets/logos/hinged.svg';
import { ReactComponent as LogoHorizontal } from './assets/logos/horizontal.svg';

const SKILLS = [
  {
    title: 'Development',
    description: 'Designing and creating awesome websites',
    icon: IconSkillDevelopment
  },
  {
    title: 'Prototyping & design',
    description: 'The design process that your project needs',
    icon: IconSkillPrototype
  },
  {
    title: 'Native',
    description: 'Creating user-friendly, cross-platform apps',
    icon: IconSkillApps
  },
]

const RotationOrnament = () => (
  <div className="fixed top-1/2 -translate-y-1/2 z-0">  
    <LogoCompact className="min-w-[100vw] min-h-[100vh] animate-[spin_600s_linear_infinite] fill-[#af9351] opacity-[1%]" />
  </div>
)

interface Props {};

const App: FC<Props> = () => {
  return (
    <div className={classNames("h-full text-zinc-100")}>
      <div className={styles.page}>
        <div className="text-zinc-100">
          {/* <Icon name="arrow-left-down-line" size="xlarge" /> */}
          <h2 className={styles.title}>
            A Young <span className="text-white">Creative</span><br/>Developer
          </h2>
          {false &&<LogoHorizontal fill="currentColor" className="text-zinc-100 w-full mb-4" />}
          <div className={styles.scrollWrapper}>
            <div className={styles.scrollContainter}>
              {SKILLS.map((skill, skillIndex) => {
                const Icon = skill.icon;
                return (
                  <div className={styles.skill} key={skillIndex}>
                    <Icon className="w-8 mb-4" />
                    <div>
                      <h4 className="text-xl mb-2">{skill.title}</h4>
                      <p className="text-zinc-400 leading-5 lg:leading-normal">{ skill.description}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.contactDetails}>
            <div className="mt-8">
              <h4 className="uppercase tracking-widest text-sm font-medium mb-2 text-zinc-500">Say hi</h4>
              <a href="mailto:hi@jung.gent" className="block mb-2">hi@jung.gent@junggent</a>
              <a href="tel:hi@+32497753762">+32 4 97 75 37 62</a>
            </div>
            <div className="mt-8">
              <h4 className="uppercase tracking-widest text-sm font-medium mb-2 text-zinc-500">Greetings from</h4>
              <p className="mb-2">Ghent, Belgium</p>
              <p>{dayjs().format('h:m A CET')}</p>
            </div>
            <div className="mt-8">
              <h4 className="uppercase tracking-widest text-sm font-medium mb-2 text-zinc-500">Find me on</h4>
              <p>
                <a href="https://github.com/lennertderyck" rel="noreferrer" target="_blank">GitHub</a>
                &nbsp;&nbsp;&&nbsp;&nbsp;
                <a href="https://www.instagram.com/lennertderyck/" rel="noreferrer" target="_blank">Instragram</a>
              </p>
            </div>
          </div>
          {true &&<LogoHinged fill="currentColor" className={styles.logo} />}
        </div>
      </div>
      <RotationOrnament />
    </div>
  )
}

export default App;